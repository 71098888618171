import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que es pot identificar per dues coses, per l’olor agradable i gust a regalèssia i per tornar negre quan es toca. Té forma de patata de 2 a 6 cm de diàmetre de color grogós al principi per passar a color terrós. Està cobert de petites fibril·les molt aplicades. La gleva és quasi negre, formada per petites cel·les de parets blanques que contenen les espores. Aquestes són de color terrós llises, el·lipsoidals, de 6-8 x 4-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      